
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { CrosshairPlugin } from "chartjs-plugin-crosshair";
import { Chart, registerables, Tooltip } from "chart.js";
import dayjs from "dayjs";
import gradient from "chartjs-plugin-gradient";
import TooltipChart from "~/components/organisms/chart/TooltipChart.vue";
import { getTooltipDataFromContext, roundToNearest } from "~/operations/charts";

let executed = false;

type dataSets = Array<{
  x: string;
  y: number;
}>;

type labels = Array<string | number>;

@Component({
  components: {
    TooltipChart,
  },
})
export default class SwarmTrendChart extends Vue {
  @Prop() labels: labels;
  @Prop() priceDataSets: dataSets;
  @Prop() recommendationPhaseDataSets: dataSets;

  // eslint-disable-next-line @typescript-eslint/ban-types
  tooltipData: object = {};
  SwarmChartsTooltip = "SwarmChartsTooltip";
  asd: any = {};
  // eslint-disable-next-line @typescript-eslint/ban-types
  tooltipPosition: object = {
    xAlignleftS: 15,
    xAlignleftL: 20,
    top: 20,
    xAlignright: -65,
    breakpoint: 680,
    LeftBottom: 20,
    center: -110,
  };
  min = 20;
  max = 125;
  executed = false;
  lowNum = 0;

  get _labels() {
    return this.labels;
  }

  get _priceDataSets() {
    return this.priceDataSets;
  }

  get _recommendationPhaseDataSets() {
    if (this.recommendationPhaseDataSets.length === 0) {
      return [];
    }
    if (!this.executed) {
      let low = this.recommendationPhaseDataSets.reduce((previous, current) => {
        return current.y < previous.y ? current : previous;
      });
      this.lowNum = low.y;
      this.executed = true;

      return this.recommendationPhaseDataSets.map((ele) => {
        return { ...ele, y: ele.y - this.lowNum };
      });
    } else {
      return this.recommendationPhaseDataSets.map((ele) => {
        return { ...ele };
      });
    }
  }

  setTooltipData(data) {
    this.tooltipData = data;
  }

  externalTooltipHandler = (context) => {
    if (context.tooltip.body) {
      this.setTooltipData(getTooltipDataFromContext(context));
      this.tooltipData = context;
    }
  };

  mounted() {
    this.initializeChart();
    requestAnimationFrame(() => {
      this.registerCrossHairPlugin();
    });
  }

  initializeChart() {
    requestAnimationFrame(() => {
      Chart.register(...registerables);
      Chart.register(gradient);
      this.fetchMinMaxValues();
      this.initRenderChart();
      //@ts-ignore
      Tooltip.positioners.custom = function (elements, eventPosition) {
        return {
          x: eventPosition.x,
          y: eventPosition.y,
        };
      };
    });
  }

  chartjsOptions = {
    type: "line",
    data: {
      labels: this._labels,
      datasets: [
        {
          label: "Preis",
          pointHoverBackgroundColor: "#000",
          pointHoverBorderColor: "#fff",
          pointHoverBorderWidth: 3,
          pointHoverRadius: 6,
          data: this._priceDataSets,
          borderColor: "#000",
          borderWidth: 2,
          pointRadius: 0,
          backgroundColor: "transparent",
          fill: true,
          gradient: {
            backgroundColor: {
              axis: "y",
              colors: {
                0: "rgba(142, 142,142, 0.0)",
                100: "rgba(142, 142,142, 0.05)",
              },
            },
          },
        },
        {
          type: "bar",
          label: "Empfehlung",
          data: this._recommendationPhaseDataSets,
          borderColor: ["rgba(253, 200, 53, 0.5)"],
          borderWidth: 0,
          pointRadius: 0,
          pointHoverBackgroundColor: "rgba(203, 208, 210, 0)",
          pointHoverBorderColor: "transparent",
          pointHoverBorderWidth: 0,
          pointHoverRadius: 0,
          backgroundColor: ["rgba(253, 200, 53, 0.5)"],
          steppedLine: true,
          fill: true,
          yAxisID: "y2",
        },
      ],
    },
    options: {
      aspectRatio: () => (window.innerWidth < 700 ? 1 | 0.2 : 0.5 | 2),
      responsive: true,
      lineTension: 1,
      spanGaps: true,
      title: {
        display: false,
        text: "Heizölpreis-Entwicklung in Deutschland",
        fontSize: 22,
        position: "bottom" as "bottom",
      },
      hover: {
        mode: "index",
        intersect: false,
      },
      scales: {
        y: {
          max: this.max,
          min: this.min,
          position: "right",
          ticks: {
            beginAtZero: false,
            maxTicksLimit: 7,
            stepSize: 5,
            fontSize: 12,
            color: "#7d7d7d",
            callback(value) {
              return `${value} €`;
            },
          },
          scaleLabel: {
            display: false,
            labelString: "€ / 100 L",
            fontColor: "#000000",
            fontSize: 12,
          },
          grid: {
            color: "rgba( 211, 211, 219, 0.4 )",
            drawBorder: false,
            drawOnChartArea: true,
            drawTicks: true,
            tickMarkLength: 10,
          },
        },
        y2: {
          max: 12,
          ticks: {
            color: "transparent",
            callback() {
              return null;
            },
          },
          position: "right",
          grid: {
            color: "rgba( 211, 211, 219, 0.4 )",
            drawBorder: false,
            drawOnChartArea: false,
            drawTicks: true,
            tickMarkLength: 0,
          },
        },
        x: {
          type: "category",
          distribution: "linear" as "linear",
          ticks: {
            autoSkip: true,
            maxTicksLimit: 13,
            maxRotation: 0,
            fontSize: 12,
            color: (context) =>
              context.index % 2 === 0 ? "transparent" : "#7d7d7d",
            align: "right",
            callback: (context) => {
              const splittedDate = this._labels[context].toString().split(".");
              return dayjs(
                `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`,
              ).format("MMM");
            },
          },
          scaleLabel: {
            display: false,
            labelString: "",
            fontColor: "#000000",
            fontSize: 12,
          },
          grid: {
            color: "rgba( 211, 211, 219, 0.4 )",
            zeroLineColor: "#cbd0d2",
            zeroLineBorderDash: [3, 9],
            drawBorder: true,
            drawOnChartArea: true,
            drawTicks: true,
            tickMarkLength: 10,
          },
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
          external: this.externalTooltipHandler,
          mode: "index",
          position: "custom",
          intersect: false,
          displayColors: false,
          xPadding: 20,
          yPadding: 20,
          caretPadding: 5,
          cornerRadius: 5,
          borderWidth: 1,
          bodySpacing: 5,
          borderColor: "#000",
          callbacks: {
            label: function (tooltipItem) {
              if (tooltipItem.datasetIndex === 0) {
                let hoveredPrice = tooltipItem.formattedValue;
                let todaysPrice =
                  tooltipItem.dataset.data[tooltipItem.dataset.data.length - 1]
                    .y;
                return [`${hoveredPrice} €`, `Heute: ${todaysPrice} € `];
              }
            },
          },
        },
        legend: {
          display: false,
          position: "bottom" as "bottom",
          align: "start" as "start",
          labels: {
            fontColor: "rgba(0,0,0)",
            usePointStyle: true,
            boxWidth: 5,
            padding: 10,
          },
        },
        crosshair: {
          sync: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
          line: {
            color: "hsla( 10, 1%, 1%, 1 )",
            width: 1,
            dashPattern: [10, 3],
          },
        },
      },
    },
  };

  initRenderChart() {
    requestAnimationFrame(() => {
      const ctx = document.getElementById("SwarmCharts") as HTMLCanvasElement;
      this.asd = new Chart(ctx, {
        type: this.chartjsOptions.type as any,
        data: this.chartjsOptions.data,
        options: this.chartjsOptions.options,
      });
    });
  }

  registerCrossHairPlugin() {
    Chart.register(CrosshairPlugin);
  }

  fetchMinMaxValues() {
    requestAnimationFrame(() => {
      let prices = [];
      for (const ele of this._priceDataSets) {
        if (ele) {
          prices.push(ele);
        }
      }
      this.min = roundToNearest(Math.min(...prices), 5, false);
      this.max = roundToNearest(Math.max(...prices), 5, true);

      this.chartjsOptions.options.scales.y.max = this.max;
      this.chartjsOptions.options.scales.y.min = this.min;
    });
  }
}
