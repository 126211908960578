
import { Component, Prop, Vue, Watch, Emit } from "nuxt-property-decorator";
import RouterLink from "~/components/atoms/text/RouterLink.vue";
let tooltipIdChange: string = "";
@Component({
  components: {
    RouterLink,
  },
})
export default class TooltipChart extends Vue {
  @Prop() tooltipDataSet: {};
  @Prop() tooltipId: string;
  @Prop() tooltipPositionData: {
    xAlignleftS: number;
    xAlignleftL: number;
    top: number;
    xAlignright: number;
    breakpoint: number;
    LeftBottom: number;
    center: number;
  };

  @Prop() chartDataSet: [
    {
      changedAtUtc: string;
      priceData: number;
      pricePrognosisDataTop: number;
      pricePrognosisDataBottom: number;
    },
  ];

  labels: Array<string> = [];
  intersections: Array<number> = [];
  prognosisTop: Array<number> = [];
  prognosisBottom: Array<number> = [];

  getTooltip = (chart) => {
    let tooltip = document.getElementById(`my-tooltip-${this.tooltipId}`);
    let tooltip_span = document.getElementsByClassName("tooltip-span");
    let tooltip_p1 = document.getElementsByClassName("tooltip-p1");
    let tooltip_p2 = document.getElementsByClassName("tooltip-p2");
    let xAlignLeft =
      window.innerWidth >= this.tooltipPositionData.breakpoint
        ? this.tooltipPositionData.xAlignleftL
        : this.tooltipPositionData.xAlignleftS;

    for (var i = 0; i < tooltip_span.length; i++) {
      tooltip_span[i].innerHTML = chart.title;
      tooltip_p1[i].innerHTML = chart.x === undefined ? null : chart.price;
      tooltip_p2[i].innerHTML = chart.x === undefined ? null : chart.priceToday;
    }

    tooltip.style.top = chart.y + this.tooltipPositionData.top + "px";

    tooltip.style.left =
      chart.x +
      ((chart.xAlign === "left" && chart.yAlign === "bottom") ||
      (chart.xAlign === "left" && chart.yAlign === "top")
        ? xAlignLeft + this.tooltipPositionData.LeftBottom
        : chart.xAlign === "left"
        ? xAlignLeft
        : chart.xAlign === "center"
        ? this.tooltipPositionData.center
        : this.tooltipPositionData.xAlignright) +
      "px";

    return tooltip;
  };

  externalTooltipHandler = (context) => {
    const tooltipEL = this.getTooltip(context);

    if (context.opacity === 0 || context.price === undefined) {
      tooltipEL.style.opacity = "0";
      tooltipEL.style.display = "none";
    } else {
      tooltipEL.style.opacity = "1";
      tooltipEL.style.display = "block";
    }
  };
  assignChartDataSets() {
    this.chartDataSet.forEach((item) => {
      this.labels.push(item.changedAtUtc);
      this.intersections.push(item.priceData);
      this.prognosisTop.push(item.pricePrognosisDataTop);
      this.prognosisBottom.push(item.pricePrognosisDataBottom);
    });
  }
  tooltipIdChangeFu(change, id) {
    if (change !== id) {
      change !== "" && document.getElementById(`my-tooltip-${change}`)
        ? (document.getElementById(`my-tooltip-${change}`).style.opacity = "0")
        : undefined;
      tooltipIdChange = this.tooltipId;
    }
  }
  mounted() {}
  @Watch("tooltipDataSet")
  onChartDataSetChang() {
    this.tooltipIdChangeFu(tooltipIdChange, this.tooltipId);
    this.externalTooltipHandler(this.tooltipDataSet);
  }
}
