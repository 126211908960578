export const getTooltipDataFromContext = (context) => {
    return { 
      x: context.tooltip.x, 
      xAlign: context.tooltip.xAlign, 
      y: context.tooltip.y,
      yAlign: context.tooltip.yAlign, 
      title: context.tooltip.title,
      price: context.tooltip.body[0].lines[0],
      priceToday: context.tooltip.body[0].lines[1],
      opacity: context.tooltip.opacity
    };
  }

 export const roundToNearest=(num: number, nearest = 5, isRoundUp = true) => {
    const calced = num / nearest;
    return (isRoundUp ? Math.ceil(calced) : Math.floor(calced)) * nearest;
  };